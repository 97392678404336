import { useExperiments } from '@wix/fe-essentials-standalone';
import { useContext } from 'react';

import { EXPERIMENTS } from '../constants';
import { Context } from '../context';
import { useIsKnowledgeAgentSearchEnabled } from './useIsKnowledgeAgentSearchEnabled';
import { useStore } from '../zustand/stores';

export const useIsSitePickerEnabled = () => {
  const { experiments } = useExperiments({ readOnly: true });
  const { isMobile, isTablet } = useContext(Context);
  const { isAiServicesDisabled } = useStore((state) => state.featureTogglesSlice);
  const isKnowledgeAgentSearchEnabled = useIsKnowledgeAgentSearchEnabled();

  if (isAiServicesDisabled) {
    return false;
  }

  if (isMobile || isTablet) {
    return false;
  }

  return (
    experiments.enabled(EXPERIMENTS.SPECS.SITE_PICKER) &&
    isKnowledgeAgentSearchEnabled
  );
};
